<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #070911;
  &-container {
    width: 400px;
    height: 367px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(15, 17, 24, 1);
    font-size: 18px;
    font-family: "PingFangSC-Regular", "PingFang SC";
    font-weight: 400;
    color: rgba(85, 119, 224, 1);
    .el-input {
      margin-top: 16px;
    }
    &-button {
      width: 140px;
      height: 40px;
      margin: 0 auto;
      margin-top: 80px;
      text-align: center;
      line-height: 40px;
      letter-spacing: 0;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 29px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
    .sn-code {
      width: 340px;
      display: inline-flex;
      img {
        width: 40%;
        height: 40px;
        margin: 16px 0;
      }
    }
    &-title {
      font-size: 18px;
      font-weight: 400;
      color: rgba(85, 119, 224, 1);
      font-family: "PingFangSC-Regular", "PingFang SC";
      line-height: 25px;
      margin-top: 35px;
    }
  }
}
</style>

<template>
  <div class="login">
    <div class="login-container"
         :style="{height: '367px'}">
      <div class="login-container-new">
        <div class="login-container-title">
          重置密码
        </div>
        <el-input v-model="loginPassword"
                  placeholder="请输入重置新密码"
                  show-password
                  :style="{width: '340px'}"></el-input>
        <div class="login-container-button"
             @click="reset">
          重置
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'login',
  data () {
    return {
      loginPassword: ''
    }
  },
  mounted () {
  },
  computed: {
    token () {
      return window.location.hash.substring(17);
    }
  },
  created () {
  },
  methods: {
    reset () {
      this.$api.postReset_password({
        token: this.token,
        password: this.loginPassword
      }).then(() => {
        this.$message.success('重置成功');
        this.$router.push({
          name: 'login'
        });
      })
    }
  }
}
</script>
